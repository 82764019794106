<template>
    <el-dialog :close-on-click-modal="false" title="会员卡包含机构" :visible.sync="show" width="700px" class="selfInputBox">


        <!-- <el-form ref="form" label-width="80px">
        </el-form> -->

        <!-- <el-form-item class="must" label="标题" prop="">
                <el-input v-model="updateData.title" />
            </el-form-item> -->


        <!-- <el-form-item class="must" label="类型">
                <el-select v-model="updateData.type" placeholder="会员类型">
                    <el-option :label="item.name" :value="item.id" v-for="item in timeTypt" :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->


        <!-- <el-form-item label="活动时间" prop="" style="clear: both">
                <el-date-picker v-model="timeRange" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item> -->


        <!-- <el-form-item label="医院类型" class="roleListBox" style="width: 100%;">
                <el-radio style="margin-left: 20px;" v-model="updateData.hospital_type" :label="1">公立医院</el-radio>
                <el-radio v-model="updateData.hospital_type" :label="2">下属民营</el-radio>
            </el-form-item> -->


        <!-- <el-form-item label="是否启用" prop="">
                <el-switch v-model="updateData.status" :active-value="1" active-color="#5BD995" active-text="正常"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item> -->


        <!-- <el-form-item label="有效期" prop="" style="clear: both">
                <el-date-picker v-model="updateData.entry_date" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="选择日期">
                </el-date-picker>
            </el-form-item> -->



        <!-- target-order="unshift" //排序 后来居上 -->
        <el-transfer class="selfInputBox" filterable :titles="['所有机构', '包含机构']" :button-texts="['移除', '增加']"
            v-model="value" :data="data" target-order="unshift" :filter-method="filterMethod"
            filter-placeholder="请输入关键字"></el-transfer>

        <!-- :props="{key:'id',label:'userName'}" -->

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                updateData: {},

                data: [],
                value: [1, 4],

            }
        },

        watch: {

            show(value) {
                if (this.show) {
                    // 

                    for (let i = 1; i <= 15; i++) {

                        this.data.push({
                            key: i,
                            label: `备选项 ${ i }`,

                            // disabled: i % 4 === 0
                        })

                    }


                } else {
                    this.updateData = {}
                }
            },

            value(value) {
                console.log(value);
            }

        },

        computed: {

        },

        methods: {
            filterMethod(query, item) {
                console.log(query, item);
                //检索某字段包含搜索名
                return item.label.indexOf(query) > -1
            },


            // async 
            open(val) {
                this.show = true

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", val);

                // this.updateData = {
                //     is_public: 1
                // }

                // this.$http.get('/api/renew/' + val.id).then((res) => {

                //     if (res.code != 200) { //请求错误
                //         this.$message.error(res.msg);

                //     }
                //     if (res.code == 200) {

                //     }
                // }).catch((err) => {
                //     console.log(err)
                // });

            },


            save() {

                this.show = false

                //     // // 回显图片的路径
                //     // photoList: [],
                //     // // 图片的路径
                //     // dialogImageUrl: "",
                //     // console.log(this.photoList);
                //     // console.log(this.dialogImageUrl);

                //     // console.log(this.timeRange);
                //     // return

                //     // this.updateData.img = this.dialogImageUrl
                //     // this.updateData.start_time = this.timeRange[0] ? this.timeRange[0] : ""
                //     // this.updateData.end_time = this.timeRange[1] ? this.timeRange[1] : ""
                //     // // console.log(this.updateData);

                //     // this.$http.post('/api/renew', this.updateData).then((res) => {
                //     //     console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                //     //     if (res.code == 200) {
                //     //         this.show = false
                //     //         this.$emit('refresh')
                //     //     }
                //     //     if (res.code != 200) { //请求错误
                //     //         this.$message.error(res.msg);

                //     //     }
                //     // }).catch((err) => {
                //     //     console.log(err)
                //     // });

            },



        }
    }
</script>

<style lang="scss" scoped>
    // .selfInputBox {
    // }
    // /deep/.el-input__suffix {
    //     // border: 1px red solid !important;
    //     // box-sizing: content-box !important;
    //     position: relative;
    //     right: 10px;
    // }
    // /deep/.el-input__prefix {
    //     // border: 1px red solid !important;
    //     // box-sizing: content-box !important;
    //     position: absolute;
    //     left: 20px;
    // }
    // .el-select.el-select--mini,
    // .el-date-editor.el-input,
    // .el-date-editor.el-input__inner {
    //     width: 100%;
    // }
    // .uploadImg {
    //     // border: 1px red solid !important;
    //     box-sizing: content-box !important;
    //     position: relative;
    //     right: -20px;
    //     width: 148px;
    //     height: 148px;
    //     overflow: hidden;
    //     // line-height: 30px;
    //     /deep/.el-upload--picture-card {
    //         // border: 1px red solid !important;
    //         // box-sizing: content-box !important;
    //     }
    //     /deep/.el-upload__text {
    //         line-height: 30px;
    //         text-align: center;
    //         // border: 1px red solid !important;
    //         // box-sizing: content-box !important;
    //     }
    //     /deep/.el-upload-dragger {
    //         width: 100%;
    //         height: 100%;
    //         // width: 148px;
    //         // height: 148px;
    //         // line-height: 30px;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //     }
    // }

    /deep/.selfInputBox {
        // border: 1px blue solid;
        // box-sizing: content-box;
        display: flex;
    }

    /deep/.el-transfer-panel {
        flex: 5;
    }

    /deep/.el-transfer__buttons {
        // border: 1px red solid;
        // box-sizing: content-box;
        width: 80px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
    }

    /deep/.el-transfer__buttons .el-button {
        // border: 1px red solid;
        // box-sizing: content-box;
        margin: 0;
    }

    /deep/.el-transfer__buttons .el-button i {
        display: none;
    }

    /deep/.el-transfer__buttons .el-button:nth-of-type(1) {
        margin-left: 2px;
    }

    /deep/.selfInputBox .el-input__inner {
        // border: 1px blue solid;
        // box-sizing: content-box;
        // width: calc(100% - 50px) !important;
        // min-width: 200px !important;
        // max-width: 350px !important;
        position: relative;
        left: 0px;
        top: -2px;
        // width: 100px;
        min-width: 100px !important;
    }

    /deep/.el-dialog__body {
        // border: 1px blue solid;
        // box-sizing: content-box;
        padding: 30px 20px 30px 20px;
    }
</style>