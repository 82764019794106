<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">

                <el-form-item>
                    <el-input clearable v-model="data.title" placeholder="请输入关键字" />
                </el-form-item>

                <el-form-item label="类型">
                    <el-select clearable v-model="data.type" placeholder="请选择类型" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in timeTypt"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="search()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="addItem">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加会员卡
                    </el-button>
                </el-form-item>

            </el-form>
        </div>

        <el-table class="el-tableII FanfuTableBox" :data="list" stripe height="100%" border ref="multipleTable">

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="img" label="类型封面" align="center" width="100">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="GLOBAL.BASE_URL +scope.row.img" v-if="scope.row.img"
                        class="CoverImage">
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="hospital_type" label="医院类型" align="center" width="90">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.hospital_type==1?'公立医院':'下属民营'}}
                    </span>
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip prop="title" label="会员卡类型" align="left" width="120" />

            <el-table-column show-overflow-tooltip prop="money" label="活动价格(元)" align="left" width="100" />

            <el-table-column show-overflow-tooltip prop="state" label="状态" align="center" width="90">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_public" :active-value="1" active-color="#5BD995" active-text="上架"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="下架" class="switchSelfBox"
                        style="pointer-events: painted;" @change="switchStatus(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="number" label="续费数量" align="left" />


            <el-table-column show-overflow-tooltip prop="type" label="类型" align="left" width="50">
                <template slot-scope="scope">
                    {{renewType(scope.row.type)}}
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="start_time" label="开始时间" align="left" width="140" />
            <el-table-column show-overflow-tooltip prop="end_time" label="结束时间" align="left" width="140" />


            <el-table-column show-overflow-tooltip prop="update_name" label="更新人" align="left" />
            <el-table-column show-overflow-tooltip prop="upate_time" label="更新时间" align="left" width="140" />


            <el-table-column show-overflow-tooltip label="操作" align="center" width="300">
                <template slot-scope="scope">
                    <div class="operate1">

                        <el-button type="" class="green" @click="includeOrgan(scope.row)">
                            包含机构
                        </el-button>


                        <el-button type="" class="blue" @click="edit(scope.row)">
                            编辑
                        </el-button>

                        <el-button type="" class="pink" @click="del(scope.row)">
                            删除
                        </el-button>


                        <!-- <el-button type="" class="white" @click="">
                            详情
                        </el-button>
                        <el-button type="" class="green" @click="">
                            复制
                        </el-button>
                        <el-button type="" class="dirtyblue" @click="">
                            上移
                        </el-button>
                        <el-button type="" class="dirtybown" @click="">
                            下移
                        </el-button>
                        -->

                    </div>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.page_size" :total="data.total" @current-change="handleCurrentChange" />


        <memberType ref="memberType" @refresh="getMedicalWasteTypes" />

        <!-- <memberTicket ref="memberTicket" @refresh="getMedicalWasteTypes" /> -->



        <memberIncludeOrgan ref="memberIncludeOrgan" />



    </div>
</template>

<script>
    import memberType from './components/memberType'

    import memberIncludeOrgan from './components/memberIncludeOrgan'

    // import memberTicket from './components/memberTicket'

    import resizeDetector from 'element-resize-detector'

    // import {
    //     mapState,
    //     mapMutations
    // } from 'vuex'

    export default {
        components: {
            memberType,
            memberIncludeOrgan
            // memberTicket
        },
        data() {
            return {

                data: {
                    title: "",
                    type: "",
                    page: 1,
                    page_size: 15,
                    total: 0,
                },

                list: [],


                // 类型：1年、2月、3日
                timeTypt: [{
                    id: 1,
                    name: '年',
                }, {
                    id: 2,
                    name: '月',
                }, {
                    id: 3,
                    name: '日',
                }, ]

            }
        },

        computed: {
            // ...mapState('m_item', ['MedicalWasteItem']),
        },

        created() {
            // this.getList()
            this.modifyLayout();
        },

        mounted() {
            this.modifyLayout();
            this.getMedicalWasteTypes()
        },

        watch: {

            "data": {
                deep: true,
                handler: function(newVal, oldVal) {




                }
            },

        },

        methods: {
            // ...mapMutations('m_item', ['setMedicalWasteItem']),

            renewType(type) {
                // 类型：1年、2月、3日
                switch (type) {
                    case 1:
                        return "年"
                        break;
                    case 2:
                        return "月"
                        break;
                    default:
                        return "日"
                        break;
                }
            },

            getMedicalWasteTypes() {

                this.$http.get('/api/renew', this.data).then((res) => {

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        this.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getMedicalWasteTypes()
            },


            search() {
                this.data.page = 1
                this.getMedicalWasteTypes()
            },



            addItem() {
                this.$refs.memberType.open()
                // this.$refs.memberTicket.open()

            },


            edit(row) {
                console.log(row)
                this.$refs.memberType.edit(row)

                // this.$refs.memberTicket.edit(row)

            },

            switchStatus(row) {
                // console.log(row);
                // return

                this.$http.put('/api//renew/setIsPublic/' + row.id, {

                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },


            view(row) {
                console.log(row)
            },

            del(row) {

                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {

                        if (action === 'confirm') {


                            this.$http.del('/api/renew/' + row.id).then((
                                res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);

                                }
                                if (res.code == 200) {


                                    this.data = {
                                        title: "",
                                        type: "",
                                        page: 1,
                                        page_size: 15,
                                        total: 0,
                                    }

                                    this.getMedicalWasteTypes()

                                }

                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);



                            }).catch((err) => {
                                console.log(err)
                            });

                        }

                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },




            includeOrgan(val) {
                // 包含机构  


                this.$refs.memberIncludeOrgan.open(val)

            },





            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 - 35 + height;
                    if ($(".level_indexI .FanfuTableBox")[0]) {
                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 33 + height;
                    if ($(".level_indexI .el-table")[0]) {
                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }

                });

            }

        },

    }
</script>

<style lang="scss" scoped>
    .operate1 {
        // width: 400px;
        // display: block;

        // border: 1px red solid;
        // box-sizing: border-box;

        .el-button {
            color: #fff;
            border: none;
            padding: 0 20px !important;
        }

        .white {
            color: #606266;
            border: 1px #DCDFE6 solid;
            box-sizing: border-box;
        }

        .blue {
            background-color: #768DFC;
        }

        .green {
            background-color: #5BD995;
        }

        .dirtyblue {
            background-color: #4EBBC8;
        }

        .dirtybown {
            background-color: #B99216;
        }

        .pink {
            background-color: #FF754C;
        }

    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  }

  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini {
    border: 1px red dotted;
    box-sizing: border-box;
  } */


    /* .FanfuTableBox {
        border: 1px red dotted;
        box-sizing: border-box;
    } */
</style>